package com.iknova.gl.geocode.studio.front.components

import com.benasher44.uuid.uuid4
import csstype.ClassName
import kotlinx.browser.document
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.useEffect

/** Props for [GltfViewer]. */
external interface IGltfViewerProps : Props {

    /** Name of the 3D model to display. */
    var modelName: String?

    /** URL of the 3D model to display. */
    var modelUrl: String
}

/** Viewer for GLTF 3D files. */
val GltfViewer = FC<IGltfViewerProps> { props ->

    val modelViewerContainerId = uuid4().toString()

    useEffect(props.modelName, props.modelUrl) {
        document.getElementById(modelViewerContainerId)!!.innerHTML = """
            <model-viewer alt="${props.modelName ?: "GLTF file"}" class="methodEditor-rightPan-modelViewer" camera-controls
             src="${props.modelUrl}" shadow-intensity="1" autoplay touch-action="pan-y">
             </model-viewer>
        """.trimIndent()
    }

    ReactHTML.div {
        id = modelViewerContainerId
        className = ClassName("methodEditor-rightPan-box")
    }
}
