package com.iknova.gl.geocode.studio.front.components

import com.iknova.gl.geocode.studio.front.DeploymentInfo
import csstype.ClassName
import csstype.Padding
import csstype.px
import emotion.react.css
import mui.material.Box
import mui.material.Typography
import mui.material.styles.TypographyVariant
import react.FC
import react.Props
import react.dom.html.AnchorTarget
import react.dom.html.ReactHTML

internal external interface FooterProps : Props {
    var deploymentInfo: DeploymentInfo
}

/** Footer component displayed at the bottom of the screen sending to KAD-Base Studio release note */
internal val Footer = FC<FooterProps> { props ->
    Box {
        className = ClassName("footer")

        Typography {
            variant = TypographyVariant.body2
            ReactHTML.a {
                href = "https://www.iknova.com"
                className = ClassName("footer-text")
                target = AnchorTarget._blank
                +"Geocode Studio ${props.deploymentInfo.displayVersion}${props.deploymentInfo.releaseDate?.let { " du $it" } ?: ""}"
            }
        }

        ReactHTML.div {
            css { padding = Padding(0.px, 10.px) }
            +"-"
        }

        Typography {
            variant = TypographyVariant.body2
            ReactHTML.a {
                href = props.deploymentInfo.docLink
                className = ClassName("footer-text")
                target = AnchorTarget._blank
                +"Documentation utilisateur"
            }
        }
    }
}
