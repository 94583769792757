package com.iknova.gl.geocode.studio.front.api

import com.iknova.gl.geocode.studio.front.RenderingEngine
import com.iknova.gl.geocode.studio.front.wrappers.connect
import com.iknova.gl.geocode.studio.shared.deploymentInfoEvent
import com.iknova.gl.geocode.studio.shared.executeMethodRequest
import com.iknova.gl.geocode.studio.shared.geoMethodResultEvent
import com.iknova.gl.geocode.studio.shared.scalarMethodResultEvent

/** Socket client for event management between front and back. */
internal object SocketClient {

    private val buildSocket = connect("/geocode")

    private lateinit var methodExecutionGeoCallback: (String) -> Unit
    private lateinit var methodExecutionScalarCallback: (String) -> Unit
    private lateinit var deploymentInfoCallback: (String, String?, String) -> Unit

    /** Connect to the backend. */
    init {
        buildSocket.on(deploymentInfoEvent) { v, d, l -> deploymentInfoCallback(v, d, l) }
        buildSocket.on(scalarMethodResultEvent) { arg, _ -> methodExecutionScalarCallback(arg.toString()) }
        buildSocket.on(geoMethodResultEvent) { arg, _ -> methodExecutionGeoCallback(arg.toString()) }
    }

    /** Execute given [script]. */
    fun executeMethod(
        script: String,
        renderingEngine: RenderingEngine,
        deflection: Float,
        geoCallback: (String) -> Unit,
        scalarCallback: (String) -> Unit
    ) {
        methodExecutionGeoCallback = geoCallback
        methodExecutionScalarCallback = scalarCallback
        buildSocket.emit(executeMethodRequest, script, renderingEngine.name, deflection)
    }

    fun setDeploymentInfoCallback(deploymentInfoCallback: (String, String?, String) -> Unit) {
        this.deploymentInfoCallback = deploymentInfoCallback
    }
}
