package com.iknova.gl.geocode.studio.front.components

import com.iknova.gl.geocode.studio.front.RenderingEngine
import com.iknova.gl.geocode.studio.front.RenderingEngine.OPEN_CASCADE
import csstype.AlignItems
import csstype.Display
import csstype.JustifyContent
import csstype.px
import mui.icons.material.PlayArrow
import mui.material.*
import mui.system.sx
import react.*
import react.dom.html.ReactHTML
import react.dom.onChange

internal external interface MethodEditorToolbarProps : Props {
    var executeMethod: () -> Unit
    var renderingEngine: RenderingEngine
    var setRenderingEngine: (RenderingEngine) -> Unit
    var deflection: Float
    var setDeflection: (Float) -> Unit
}

internal val EngineSelect: FC<SelectProps<RenderingEngine>> = Select
internal val renderingEnginesList = RenderingEngine.values().toList()

/** Advanced toolbar for MethodEditor */
internal val MethodEditorToolbar = FC<MethodEditorToolbarProps> { props ->

    var deflectionText by useState(props.deflection.toString())
    var error by useState(false)

    Box {
        sx {
            display = Display.flex;
            justifyContent = JustifyContent.flexStart
            alignItems = AlignItems.center
            height = 90.px
            gap = 20.px
        }
        Tooltip {
            title = ReactNode("Exécuter le geocode")
            placement = TooltipPlacement.bottom
            disableInteractive = true
            ReactHTML.span {
                IconButton {
                    disabled = false
                    children = PlayArrow.create()
                    color = IconButtonColor.success
                    onClick = { props.executeMethod() }
                }
            }
        }
        Tooltip {
            title = ReactNode("Moteur de rendu")
            placement = TooltipPlacement.bottom
            disableInteractive = true
            ReactHTML.span {
                EngineSelect {
                    sx { width = 250.px }
                    value = props.renderingEngine
                    onChange = { event, _ -> props.setRenderingEngine(event.target.value.unsafeCast<RenderingEngine>()) }
                    renderValue = { selected -> ReactNode(selected.caption) }
                    renderingEnginesList.forEach {
                        MenuItem {
                            key = it.name
                            value = it.asDynamic()
                            ListItemText { primary = ReactNode(it.caption) }
                        }
                    }
                }
            }
        }
        if (props.renderingEngine == OPEN_CASCADE) {
            Tooltip {
                sx { marginLeft = 25.px }
                title = ReactNode("Deflection")
                placement = TooltipPlacement.bottom
                disableInteractive = true
                ReactHTML.span {
                    TextField {
                        sx { width = 250.px }
                        label = ReactNode("Deflection")
                        value = deflectionText
                        this.error = error
                        onChange = { event -> deflectionText = event.target.asDynamic().value }
                        onBlur =
                            { _ -> deflectionText.toFloatOrNull()?.let { props.setDeflection(it); error = false } ?: run { error = true } }
                    }
//                Slider {
//                    sx { width = 250.px }
//                    value = props.deflection.toInt()
//                    step = 1
//                    onChange = { _, _, value -> props.setDeflection(value.toFloat()) }
//                    min = 1
//                    max = 100
//                }
                }
            }
        }
    }
}
