package com.iknova.gl.geocode.studio.front.components

import com.iknova.gl.geocode.studio.front.wrappers.AceEditor
import csstype.ClassName
import kotlinx.browser.window
import kotlinx.js.timers.setTimeout
import org.w3c.dom.events.Event
import react.FC
import react.useEffect
import react.useState
import kotlin.time.Duration.Companion.milliseconds

/** Base class for editors based on Ace. */
open class AceBasedEditor(aceMode: String) {

    val editorComponent = FC<IEditorProviderProps> { props ->

        var fileContent by useState(props.content)

        fun save() {
            val content = fileContent
            props.saveFileContent(content)
        }

        useEffect { setTimeout(500.milliseconds) { window.dispatchEvent(Event("resize")) } } // Fixes scrolling issues with Ace editor

        AceEditor {
            className = ClassName("fileEditor-input-box-tabsContent-editor")
            placeholder = "Write some text here !"
            mode = aceMode
            theme = props.theme
            name = "Geocode"
            value = fileContent
            onChange = { value, _ -> fileContent = value }
            onBlur = { save() }
            fontSize = 16
            showPrintMargin = true
            highlightActiveLine = true
        }
    }
}

object BasecodeEditor : AceBasedEditor("yaml")
object CssEditor : AceBasedEditor("css")
object JavascriptEditor : AceBasedEditor("javascript")
object MarkdownEditor : AceBasedEditor("markdown")
object TextEditor : AceBasedEditor("text")
object JsonEditor : AceBasedEditor("json")
