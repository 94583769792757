package com.iknova.gl.geocode.studio.front

import com.iknova.gl.geocode.studio.front.api.SocketClient
import com.iknova.gl.geocode.studio.front.components.Footer
import com.iknova.gl.geocode.studio.front.components.MethodEditor
import csstype.ClassName
import kotlinx.browser.document
import mui.material.Box
import react.VFC
import react.create
import react.dom.client.createRoot
import react.useEffectOnce
import react.useState

data class DeploymentInfo(
    val displayVersion: String,
    val releaseDate: String?,
    val docLink: String,
)

/** Root application component. */
val App = VFC {

    var deploymentInfo by useState(
        DeploymentInfo("vx.y.z", null, "https://knovapedia.kad-office.com/kad/Langage_geocode")
    )

    useEffectOnce { SocketClient.setDeploymentInfoCallback { v, d, l -> deploymentInfo = DeploymentInfo(v, d, l) } }

    Box {
        className = ClassName("app-box")
        +MethodEditor.create()
        Footer { this.deploymentInfo = deploymentInfo }
    }
}

/** Entry point of Geocode Studio frontend. */
fun main() = createRoot(document.getElementById("rootContainer")!!).render(App.create())
