package com.iknova.gl.geocode.studio.front.components

import com.iknova.gl.geocode.studio.front.RenderingEngine
import com.iknova.gl.geocode.studio.front.api.SocketClient
import com.iknova.gl.geocode.studio.front.layoutState
import com.iknova.gl.geocode.studio.front.onPanelResize
import com.iknova.gl.geocode.studio.front.wrappers.ReflexContainer
import com.iknova.gl.geocode.studio.front.wrappers.ReflexElement
import com.iknova.gl.geocode.studio.front.wrappers.ReflexSplitter
import csstype.ClassName
import kotlinx.browser.localStorage
import kotlinx.browser.window
import mui.material.Box
import mui.material.Divider
import mui.material.Orientation
import org.w3c.dom.events.Event
import react.VFC
import react.create
import react.dom.html.ReactHTML
import react.useMemo
import react.useState

/** Customized Editor for geocode. */
val MethodEditor = VFC {

    var script by useState(localStorage.getItem("geocodeScript") ?: "")
    var geoResult by useState<String>()
    var scalarResult by useState("Le résultat de la méthode s'affiche ici")

    var renderingEngine by useState(
        localStorage.getItem("renderingEngine")?.let { RenderingEngine.valueOf(it) } ?: RenderingEngine.EMBEDDED
    )

    var deflection by useState(localStorage.getItem("deflection")?.toFloatOrNull() ?: 10.0f)

    val saveScript = useMemo<(String) -> Unit> {
        {
            localStorage.setItem("geocodeScript", it)
            script = it
        }
    }

    val saveRenderingEngine = useMemo<(RenderingEngine) -> Unit> {
        {
            localStorage.setItem("renderingEngine", it.name)
            renderingEngine = it
        }
    }

    val saveDeflection = useMemo<(Float) -> Unit> {
        {
            localStorage.setItem("deflection", it.toString())
            deflection = it
        }
    }

    Box {
        className = ClassName("methodEditor")

        MethodEditorToolbar {
            executeMethod = {
                geoResult = null
                scalarResult = "Méthode en cours d'exécution..."
                SocketClient.executeMethod(script, renderingEngine, deflection, { geoResult = it }, { scalarResult = it })
            }
            this.renderingEngine = renderingEngine
            setRenderingEngine = saveRenderingEngine
            this.deflection = deflection
            setDeflection = saveDeflection
        }

        Divider { orientation = Orientation.horizontal }

        ReflexContainer {
            orientation = "vertical"

            ReflexElement {
                name = "method_editor_left_pan"
                flex = layoutState
                onResize = { e ->
                    onPanelResize(e)
                    window.dispatchEvent(Event("resize"))
                }

                JavascriptEditor.editorComponent {
                    theme = "chrome"
                    content = script
                    saveFileContent = saveScript
                }
            }

            +ReflexSplitter.create()

            ReflexElement {
                name = "method_editor_right_pan"
                if (geoResult != null) {
                    GltfViewer {
                        modelName = "Geocode"
                        modelUrl = "data:model/gltf-binary;base64,$geoResult"
                    }
                } else {
                    ReactHTML.div {
                        className = ClassName("methodEditor-rightPan-box")
                        ReactHTML.pre {
                            +scalarResult
                        }
                    }
                }
            }
        }
    }
}
