package com.iknova.gl.geocode.studio.shared

/** Event from server to communicate deployment info (version, release date, doc link). */
const val deploymentInfoEvent = "deploymentInfo"

/** Request to clear logs cache. */
const val executeMethodRequest = "executeMethod"

/** Request to clear logs cache. */
const val scalarMethodResultEvent = "scalarResult"

/** Request to clear logs cache. */
const val geoMethodResultEvent = "geoResult"

/** Request to clear logs cache for export. */
const val executeMethodExportRequest = "executeExportMethod"

/** Request to clear logs cache for export. */
const val scalarMethodResultExportEvent = "scalarResultExport"

/** Request to clear logs cache for export. */
const val geoMethodResultExportEvent = "geoResultExport"
