package com.iknova.gl.geocode.studio.front

import com.iknova.gl.geocode.studio.front.wrappers.ReflexResizeEvent
import kotlinx.browser.localStorage

internal var layoutState = localStorage.getItem("layoutState")?.toDoubleOrNull() ?: 0.5

/** Modifies the Split Panel ratio saved every time it is interacted with */
internal fun onPanelResize(event: ReflexResizeEvent) {
    layoutState = event.component.props.flex!!
    localStorage.setItem("layoutState", layoutState.toString())
}
